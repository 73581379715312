/*
 * @Date: 2023-07-25 16:55:01
 * @LastEditTime: 2023-10-10 17:57:54
 * @Description:
 * @FilePath: \szlsnk-user-mp\src\api\video.js
 */
import { Http } from "@/utils/http"
export class VideoModel extends Http {
  
 //获取声网视频聊天token
  getAgoraToken(params) {
    return this.post("/user/agora/token",params)
  }
  // 视频聊天选择方式
  getAgoraVideoMode() {
    return this.post('/user/agora/video/mode')
  }
  // 腾讯云音视频聊天获取userSig
  getTrtcUserSig(params) {
    return this.post('/user/trtc/userSig', params)
  }
}
export const videoModel = new VideoModel()
