<template>
  <div :class="['content']">
    <div :class="['video-chat']">
      <div id="local-player" class="local-player" />
      <div id="remote-player" class="remote-player" @click.stop="onZoomIn" />
      <div class="time">
        {{ minutes }}:{{ seconds }}
      </div>
      <div class="button">
        <van-button type="danger" round @click="onExit">
          挂断
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { videoModel } from "@/api/video"
import rtc from '@/assets/js/mixin/rtc'
import TRTC from 'trtc-sdk-v5/trtc.js'
export default {
  mixins: [rtc],
  data() {
    return {
      trtc: null,
      timer: null,
      title: '视频',
      dialogVisible: false,
      timeMeter: null,
      minutes: 0,
      seconds: 0,
      type: '',
      sdkAppId: null,
      secretKey: null,
      userId: null,
      roomId: null,
      cameraId: '',
      microphoneId: '',
      inviteUserSig: ''
    }
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite'
    },
    isEnLang() {
      return this.$i18n.locale === 'en'
    },
    showInviteLink() {
      return this.isHostMode && (this.isEntered || this.isShared) && this.inviteLink
    }
  },
   watch: {
    // 监听 minutes 和 seconds 的变化，当个位数小于 10 时，在前面补零
    minutes: 'formatTime',
    seconds: 'formatTime',
     cameraId(val) {
      this.switchDevice('video', val)
    },
    microphoneId(val) {
      this.switchDevice('audio', val)
    }
  },
  beforeDestroy() {
    this.resetMeterTimer()
    this.localTracks.videoTrack && this.localTracks.videoTrack.stop()
    this.localTracks.videoTrack && this.localTracks.videoTrack.close()
    this.localTracks.audioTrack && this.localTracks.audioTrack.stop()
    this.localTracks.audioTrack && this.localTracks.audioTrack.close()
    this.remoteUsers = {}
  },
  async mounted() {
    this.show()
  },
  created() {
    this.formatTime() // 初始化时执行一次，以确保时间格式正确
  },
  methods: {
    startMeterTimer() {
      if (this.timeMeter === null) {
        this.timeMeter = setInterval(() => {
          if (this.seconds < 59) {
            this.seconds++
          } else {
            this.seconds = 0
            this.minutes++
          }
        }, 1000)
      }
    },
    resetMeterTimer() {
      clearInterval(this.timeMeter)
      this.timeMeter = null
      this.minutes = 0
      this.seconds = 0
    },
    formatTime() {
      this.minutes = Number(this.minutes) < 10 ? '0' + Number(this.minutes) : this.minutes
      this.seconds = Number(this.seconds) < 10 ? '0' + Number(this.seconds) : this.seconds
    },
    //主动离开
    async onExit() {
      if (this.dialogVisible) {
        this.resetMeterTimer()
        await this.exitRoom()
      }else{
        this.Leave()
      }
    },
    async show() {
      await this.initTRTC()
      // 获取所有音视频设备。
      TRTC.isSupported().then(checkResult => {
        // 不支持使用 SDK，引导用户使用最新版的 Chrome 浏览器。
        if (!checkResult.result) {
          this.$toast('当前浏览器不支持！')
          return
        }
        this.sharRTC()
      })
    },
    // 初始化
    initTRTC() {
      if (this.trtc) return
      this.trtc = TRTC.create()
    },
    // 开始
    async sharRTC() {
      videoModel
        .getTrtcUserSig({ fzlsh: this.$route.query.fzlsh, type: this.$route.query.videoType  })
        .then((res) => {
          if (res.code === 200) {
            // 用户信息
            this.roomId = res.data.roomId
            this.userId = res.data.userId
            this.userSig = res.data.userSig
            this.sdkAppId = res.data.sdkAppId
            // 连接
            this.dialogVisible = true
            this.enterRoom()
          }
        })
        .catch(() => {
          this.$toast('网络错误，请重新尝试')
        })
    },
     // 客户离开信道
    async Leave() {
      clearInterval(this.timer)
      this.timer = null
      this.roomId = null
      this.userId = null
      this.userSig = null
      this.sdkAppId = null
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/styles/variable.less";
.content {
  height: 100%;
  width: 100%;
}
.video-chat {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  .local-player {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 240px;
    height: 320px;
    z-index: 2000;
  }
  .remote-player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500;
  }
  .time{
    position: absolute;
    bottom: 230px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2001;
    color:#fff;
  }
  .button {
    position: absolute;
    bottom: 120px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index:2001;
    .van-button--danger {
      width: 180px;
    }
  }
}
</style>
