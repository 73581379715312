/*
 * @Description: 音视频通话集成
 * @Date: 2022-03-14 17:15:23
 * @LastEditTime: 2023-10-23 09:43:44
 */
import TRTC from 'trtc-sdk-v5/trtc.js'

export default {
  data() {
    return {
      trtc: null,
      isEntering: false,
      isEntered: false,
      isMutedVideo: false,
      isMutedAudio: false,
      isPlayingVideo: false,
      isShared: false
    }
  },

  methods: {
    initTRTC() {
      if (this.trtc) return
      this.trtc = TRTC.create()
      console.log('trtc instance created.')
    },

    async enterRoom() {
      if (this.isEntering || this.isEntered) {
        return
      }
      this.isEntering = true
      this.initTRTC()
      try {
        console.warn(this.trtc)
        await this.trtc.enterRoom({
          strRoomId: this.roomId,
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig
        })
        this.isEntering = false
        this.isEntered = true

        this.installEventHandlers()
        this.startGetAudioLevel()
        this.handleStartLocalAudio()
        if (this.$route.query.videoType == '1' ) {
          this.handleStartLocalVideo()
        }
        console.log(`Enter room [${this.roomId}] success.`)
      } catch (error) {
        this.isEntering = false
        this.$toast(`Enter room ${this.roomId} failed, please check your params. Error: ${error.message}`)
        throw error
      }
    },

    async handleStartLocalAudio() {
      this.initTRTC()
      try {
        await this.trtc.startLocalAudio({
          option: {
            microphoneId: this.microphoneId
          }
        })
        this.isMutedAudio = false
        console.log('Local audio started successfully')
      } catch (error) {
        this.$toast('获取麦克风失败，请检查设备！')
        console.log(`Local audio is failed to started. Error: ${error.message}`)
        throw error
      }
    },

    async handleStopLocalAudio() {
      this.initTRTC()
      try {
        await this.trtc.stopLocalAudio()
        console.log('Local audio stopped successfully')
      } catch (error) {
        console.log(`Local audio is failed to stopped. Error: ${error.message}`)
        throw error
      }
    },

    async handleStartLocalVideo() {
      this.initTRTC()
      try {
        console.warn('handleStartLocalVideo', this.cameraId)
        await this.trtc.startLocalVideo({
          view: 'local-player',
          option: {
            cameraId: this.cameraId,
            profile: '480p'
          }
        })
        this.isPlayingVideo = true
        this.isMutedVideo = false
        console.log('Local video started successfully')
      } catch (error) {
        if (this.$route.query.videoType == '1') {
          this.$toast('获取摄像头失败，请检查设备！')
        }
        console.log(`Local video is failed to started. Error: ${error.message}`)
        throw error
      }
    },

    async handleStopLocalVideo() {
      this.initTRTC()
      try {
        await this.trtc.stopLocalVideo()
        this.isPlayingVideo = false
        console.log('Local audio stopped successfully')
      } catch (error) {
        console.log(`Local audio is failed to stopped. Error: ${error.message}`)
        throw error
      }
    },

    async exitRoom() {
      if (!this.isEntered || this.isExiting) {
        return
      }
      this.isExiting = true
      this.stopGetAudioLevel()

      try {
        await this.trtc.exitRoom()
        this.isExiting = false
        this.isEntered = false
        this.uninstallEventHandlers()
        await this.trtc.stopLocalVideo()
        this.isPlayingVideo = false
        await this.trtc.stopLocalAudio()
        if (this.isShared) this.handleStopShare()
        console.log('Exit room success.')
        // 离开房间，关闭弹窗
        this.Leave()
      } catch (error) {
        this.isExiting = false
        console.log(`Exit room failed. Error: ${error.message}`)
        throw error
      }
    },

    async muteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: true })
        this.isMutedVideo = true
        console.log('Mute video success.')
      } catch (error) {
        console.log(`Mute video error: ${error.message}`)
      }
    },

    async muteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: true })
        this.isMutedAudio = true
        console.log('Mute audio success.')
      } catch (error) {
        console.log(`Mute audio error: ${error.message}`)
      }
    },

    async unmuteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: false })
        this.isMutedVideo = false
        console.log('Unmute video success.')
      } catch (error) {
        console.log(`Unmute video error: ${error.message}`)
      }
    },

    async unmuteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: false })
        this.isMutedAudio = false
        console.log('Unmute audio success.')
      } catch (error) {
        console.log(`Unmute audio error: ${error.message}`)
      }
    },

    async switchDevice(type, deviceId) {
      if (!this.isEntered) return
      try {
        if (type === 'video') {
          await this.trtc.updateLocalVideo({
            option: { cameraId: deviceId }
          })
        }
        if (type === 'audio') {
          await this.trtc.updateLocalAudio({
            option: { microphoneId: deviceId }
          })
        }
        console.log(`Switch ${type} device success.`)
      } catch (error) {
        console.error('switchDevice failed', error)
        console.log(`Switch ${type} device failed.`)
      }
    },

    startGetAudioLevel() {
      this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, (event) => {
        event.result.forEach(({ userId, volume }) => {
          const isMe = userId === '' // 当 userId 为空串时，代表本地麦克风音量。
          if (isMe) {
            console.log(`my volume: ${volume}`)
          } else {
            console.log(`user: ${userId} volume: ${volume}`)
          }
        })
      })
      this.trtc.enableAudioVolumeEvaluation(2000)
    },

    stopGetAudioLevel() {
      this.trtc && this.trtc.enableAudioVolumeEvaluation(-1)
    },

    installEventHandlers() {
      this.trtc.on(TRTC.EVENT.ERROR, this.handleError)
      this.trtc.on(TRTC.EVENT.KICKED_OUT, this.handleKickedOut)
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter)
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit)
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable)
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable)
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable)
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable)
    },

    uninstallEventHandlers() {
      this.trtc.off(TRTC.EVENT.ERROR, this.handleError)
      this.trtc.off(TRTC.EVENT.KICKED_OUT, this.handleKickedOut)
      this.trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter)
      this.trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit)
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable)
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable)
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable)
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable)
    },

    handleError(error) {
      console.log(`Local error: ${error.message}`)
      alert(error)
    },

    async handleKickedOut(event) {
      console.log(`User has been kicked out for ${event.reason}`)
      this.trtc = null
      await this.exitRoom()
    },

    handleRemoteUserEnter(event) {
      const { userId } = event
      console.log(`Remote User [${userId}] entered`)
      this.startMeterTimer()
    },

    async handleRemoteUserExit(event) {
      console.log(`Remote User [${event.userId}] exit`)
      this.$toast('对方已离开，关闭聊天页面！')
      await this.exitRoom()
    },

    handleRemoteVideoAvailable(event) {
      console.warn('handleRemoteVideoAvailable', event)
      const { userId, streamType } = event
      try {
        console.log(`[${userId}] [${streamType}] video available`)
        this.$nextTick(async() => {
          await this.trtc.startRemoteVideo({ userId, streamType, view: 'remote-player' })
        })
        console.log(`Play remote video success: [${userId}]`)
      } catch (error) {
        console.log(`Play remote video failed: [${userId}], error: ${error.message}`)
      }
    },

    handleRemoteVideoUnavailable(event) {
      console.log(`[${event.userId}] [${event.streamType}] video unavailable`)
      const { streamType } = event
      this.trtc.stopRemoteVideo({ userId: event.userId, streamType })
    },

    handleRemoteAudioUnavailable(event) {
      console.log(`[${event.userId}] audio unavailable`)
    },

    handleRemoteAudioAvailable(event) {
      console.log(`[${event.userId}] audio available`)
    }
  }
}
